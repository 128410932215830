import {
  type ActionFunctionArgs,
  json,
  type LoaderFunctionArgs,
} from '@remix-run/node';
import { Heading } from '@ck/ui';
import { authenticator } from '../../auth.server';
import type { MetaFunction } from '@remix-run/node';
import { AuthorizationError } from 'remix-auth';
import { Form } from '@remix-run/react';
import { Button } from '@ck/ui';
import { getSession, sessionStorage } from '../../session.server';
import { logger } from '../../tracing.server';

export const meta: MetaFunction = () => {
  return [
    {
      title: 'Logg Inn',
      description: 'Inloggingside',
    },
  ];
};

export const action = async ({ request }: ActionFunctionArgs) => {
  try {
    return await authenticator.authenticate('cognito', request, {
      throwOnError: true,
    });
  } catch (error) {
    if (error instanceof Response) throw error;
    if (error instanceof AuthorizationError) {
      return json({ message: error.message } as const, { status: 401 });
    }
    logger.error(
      'Authentication request failed with non-response and non-authorization error: %O',
      error,
    );
    throw error;
  }
};

export const loader = async ({ request }: LoaderFunctionArgs) => {
  await authenticator.isAuthenticatedWithTokenExpiryCheck(request, {
    successRedirect: '/dashboard',
  });

  const session = await getSession(request.headers.get('Cookie'));

  return {
    headers: {
      'Set-Cookie': await sessionStorage.destroySession(session),
    },
    status: 401,
  };
};

export default function AuthSigninPage() {
  return (
    <main className="max-w-4xl mx-auto py-16 min-h-screen flex items-center justify-center">
      <div className="bg-white p-8 border border-black rounded w-2xl">
        <Heading>Logg inn</Heading>
        <div className="mt-4">
          <Form method="post">
            <Button type="submit" variant="primary" className="w-full">
              Logg inn
            </Button>
          </Form>
        </div>
      </div>
    </main>
  );
}
